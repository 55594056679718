import clsx from 'clsx';
import { ReactNode } from 'react';

import CSS from './CommonLink.module.scss';

interface CommonLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  size?: 'medium' | 'small' | 'extraSmall';
  variant?: 'default' | 'gray';
  [key: string]: any;
}

function CommonLink({
  children,
  href,
  className,
  size = 'medium',
  variant = 'default',
  ...props
}: CommonLinkProps) {
  const classes = clsx(CSS.Link, className, {
    [CSS.LinkSmall]: size === 'small',
    [CSS.LinkExtraSmall]: size === 'extraSmall',
    [CSS.LinkGray]: variant === 'gray',
  });

  return (
    <a href={href} className={classes} {...props}>
      {children}
    </a>
  );
}

export default CommonLink;
