import { useQuery } from '@tanstack/react-query';

import { getSession } from './getSession';

export const useSession = () => {
  const session = useQuery({
    queryKey: ['session'],
    queryFn: getSession,
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    notifyOnChangeProps: ['data', 'error'],
  });

  const status =
    session.isLoading || session.isFetching
      ? 'loading'
      : session.data
      ? 'authenticated'
      : 'unauthenticated';

  return {
    status,
    refetch: session.refetch,
    data: session.data,
  };
};
